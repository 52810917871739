
import api from "@/api";
import { BrandModel, SessionCreateModel } from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class CreateSessionDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  private loading = false;
  private brands: Array<BrandModel> = [];
  private model: SessionCreateModel = {
    name: "",
    brandId: "",
    drinkPrice: 0.0,
    maxOrder: 0.0,
    deploymentId: "",
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private created() {
    this.getBrands();
  }

  private async getBrands() {
    const response = await api.BrandService.apiBrandGet();
    this.brands = response.data;
  }

  private async onSessionCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      if (this.$route.params.deploymentId) {
        this.model.deploymentId = this.$route.params.deploymentId;
      }
      await api.SessionService.apiSessionPost(this.model);
      snackbarModule.setSnackbarMessage("Session created");
      this.$emit("created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create session");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }

  get filteredBrands(): Array<BrandModel> {
    if (this.brands.length > 0) {
      return this.brands.filter((x) => x.archived == false);
    }
    return [];
  }
}
