
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import { SessionUpdateModel, BrandModel } from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class UserUpdateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) sessionId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private brands: Array<BrandModel> = [];
  private model: SessionUpdateModel = {
    name: "",
    brandId: "",
    drinkPrice: 0,
    maxOrder: 0,
    deploymentId: "",
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const response = await api.SessionService.apiSessionSessionIdGet(
      this.sessionId
    );
    this.model = response.data;
  }

  private async created() {
    this.getBrands();
  }

  private async getSessionById() {
    const response = await api.SessionService.apiSessionSessionIdGet(
      this.sessionId
    );
    this.model = response.data;
  }

  private async getBrands() {
    const response = await api.BrandService.apiBrandGet();
    this.brands = response.data;
  }

  private async onSessionUpdate() {
    if (!this.form?.validate()) {
      return;
    }

    try {
      this.loading = true;
      if (this.$route.params.deploymentId) {
        this.model.deploymentId = this.$route.params.deploymentId;
      }
      await api.SessionService.apiSessionSessionIdPut(
        this.sessionId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Session updated");
      this.$emit("updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update session");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
